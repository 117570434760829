/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable no-prototype-builtins */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Tab,
  Typography,
} from '@mui/material';
// import Download from 'assets/images/b-download.png';
import Bupload from 'assets/images/bupload.png';
import Delete from 'assets/images/deleteicon.png';
import Success from 'assets/images/success.png';
import Upload from 'assets/images/uploadnow.svg';
import View from 'assets/images/viewicon.png';
import Parsed from 'assets/images/viewparsed.png';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { DocumentViewer } from 'components/CustomDocumentViewer/CustomeDocumentViewer';
import CustomInput from 'components/CustomInput/customInput';
import CustomModalView from 'components/CustomModalView/customModalView';
import CustomSelect from 'components/CustomSelect/customSelect';
import { FileBulkUploadDialog } from 'components/Fileupload/fileBulkUploadDialog';
import { FileUploadDialog } from 'components/Fileupload/fileuploadDialog';
import { PassportParsing } from 'components/ParsingDocumentView/passport';
import { PdfParsing } from 'components/ParsingDocumentView/pdfParsing';
import { ResumeParsing } from 'components/ParsingDocumentView/resume';
import {
  AffilatePrivateRoutes,
  AttorneyPrivateRoutes,
  BeneficiaryPrivateRoutes,
  PetitionerPrivateRoutes,
} from 'config';
import saveAs from 'file-saver';
import { instantState, useDebounce } from 'hooks';
import _ from 'lodash';
import { DocumentBulkDownload } from 'pages/private/Common/bulkDownload';
import { EditFileNameUploadDocuments } from 'pages/private/Common/EditFileNameUploadDocuments';
import { OrganizationUploadDocument } from 'pages/private/Organization/OrganizationUploadDocument';
import React, { useCallback, useEffect, useState } from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  addNewData,
  deleteData,
  getAllListData,
  getS3file,
  updateData,
} from 'services/CommonServices';
import { useAppSelector } from 'store/hooks';
import { PassportTypes, UserRoleType } from 'types';
import { BASE_URL, COMMON_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  changeDobFormate,
  fileTypeCheckSkillParsing,
  formateDate,
  getGenderValues,
  parsingTypeArr,
  passportDefaultValues,
  resumeDefaultValues,
} from 'utils/helper';
import Storage from 'utils/Storage';

export const UploadDocument: React.FC<any> = () => {
  const { editId } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location: any = useLocation();
  const userDetail: any = useAppSelector(
    (store: any) => store.userLogin.userInfo,
  );
  const [loader, setLoader] = useState(true);
  const [isUploadDocs, setIsUploadDocs] = useState({
    index: 0,
    show: true,
  });
  const [copyDocumentSection, setCopyDocumentSection] = useState({
    data: [],
    open: false,
  });
  const [copyDocparams, setCopyDocParams] = useState<any>({
    associated: '',
    documentType: '',
    firmId: '',
    visaId: '',
  });
  const organizationData: any = useAppSelector(
    (store: any) => store.organizationData.organization,
  );
  const [petitionerId, setPetitionerId] = useState();
  const [petitonerStatus, setPetitonerStatus] = useState();
  const debouncedParams = useDebounce(copyDocparams, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    doc: {},
    index: null,
    show: false,
  });
  const [tabValue, setTabValue] = useState(
    (location.state?.user === 'beneficiary' || userDetail.role_id === 4) &&
      location?.state?.DependentuploadDocument !== 'dependentUpload'
      ? '1'
      : location?.state?.DependentuploadDocument === 'dependentUpload'
      ? '3'
      : location?.state?.organization
      ? '4'
      : '2',
  );
  const [beneficiaryDocuments, setBeneficiaryDocuments] = useState<any>([]);
  const [petitioneryDocuments, setPetitioneryDocuments] = useState<any>([]);
  const [dependentDocuments, setDependentDocuments] = useState<any>([]);

  const [fileUploadOpen, setFileUploadOpen] = useState<{
    open: boolean;
    documentInfo: any;
    roleId: number | any;
    editDocId: any;
  }>({
    documentInfo: null,
    editDocId: null,
    open: false,
    roleId: null,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadType, setUploadType] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [recallTime, setRecallTime] = useState(0);
  const [passportValues, setPassportValues] = useState<PassportTypes>(
    passportDefaultValues,
  );
  const [resumeInfo, setResumeInfo] = useState<{
    474: string[];
    284: string;
  }>(resumeDefaultValues);
  const [parsingData, setParsingData] = useState<Array<any>>([]);

  const [leadInfo, setLeadInfo] = useState(null);
  const [params, setParams] = useState<any>({
    associated: location.pathname.includes('associated') ? true : false,
    documentType: '',
    firmId: '',
    skip: 0,
    take: 50,
    visaId: '',
  });
  const [visaTypes, setVisaTypes] = useState<any>([]);
  const [preview, setPreview] = useState(false);
  const [tempI_129, setTempI_129] = useState(false);
  const [fileSrc, setFileSrc] = useState('');
  const [copyFile, setCopyFile] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadCheck, setUploadCheck] = useState(true);
  // const [isDocumentReview, setIsDocumentReview] = useState<any>({
  //   reviewDoc: false,
  //   token: null,
  // });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [countDetails, setCountDetails] = useState<any>({
    pending: 0,
    total: 0,
  });
  const [openReview, setOpenReview] = useState({
    fileType: '',
    fileUrl: '',
    key: '',
    open: false,
  });

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getList = useCallback(() => {
    if (!_.isEmpty(location.state)) {
      if (location.state && location.state?.user === 'beneficiary') {
        getBeneficiaryList(editId, location.state?.data?.leadUserId);
      } else if (location.state && location.state?.user === 'petitioner') {
        getPetitionerList();
      } else if (
        location?.state?.DependentuploadDocument === 'dependentUpload'
      ) {
        getDependentList();
      } else {
        if (searchParams.get('leadUser') !== '' && userDetail.role_id === 4) {
          getBeneficiaryList(
            searchParams.get('lead'),
            searchParams.get('leadUser'),
          );
        } else if (location?.state?.orgCommon) {
          // empty Block
        } else {
          navigate(-1);
        }
      }
    } else {
      if (searchParams.get('leadUser') !== '' && userDetail.role_id === 4) {
        getBeneficiaryList(
          searchParams.get('lead'),
          searchParams.get('leadUser'),
        );
      } else {
        navigate(-1);
      }
    }
    visaType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const visaType = () => {
    getAllListData(`${String(COMMON_ENDPOINT.VisaTypes)}?status=active`)
      .then((resp: any) => {
        setVisaTypes(resp.data);
      })
      .catch((e: any) => {
        setLoader(false);
      });
  };
  const editUploadDocuments = useCallback(
    async (formData: FormData) => {
      try {
        await updateData(
          '',
          formData,
          COMMON_ENDPOINT.uploadDocumentFileEdit(
            fileUploadOpen?.editDocId,
            fileUploadOpen.roleId,
          ),
        );
        getList();
        setFileUploadOpen((pre) => ({
          ...pre,
          documentInfo: null,
          editDocId: null,
          open: false,
          roleId: null,
        }));
      } catch (error) {
        setLoader(false);
      }
    },
    [fileUploadOpen?.editDocId, fileUploadOpen.roleId, getList],
  );
  const createUploadDocuments = useCallback(
    async (formData: FormData | any, dialogNotClosing?: any) => {
      try {
        setLoader(true);
        await addNewData(
          formData,
          COMMON_ENDPOINT.uploadDocumentFile(
            location.state?.data?.leadUserId || editId,
            fileUploadOpen.roleId,
            location.state?.data?.personId,
          ),
        );
        getList();
        resetParseDocStateValues();
        if (
          dialogNotClosing &&
          userDetail.userRoleName === UserRoleType.Attorney
        ) {
          setLoader(false);
          setTempI_129(true);
        }
        setFileUploadOpen((pre) => ({
          ...pre,
          documentInfo: null,
          editDocId: null,
          open: false,
          roleId: null,
        }));
        setPreview(false);
      } catch (error) {
        setLoader(false);
      }
    },
    [
      editId,
      fileUploadOpen.roleId,
      getList,
      location.state?.data?.leadUserId,
      location.state?.data?.personId,
      userDetail.userRoleName,
    ],
  );

  const getPassportPaesingDoc = (entity) => {
    setPassportValues({
      countryName: {
        confidence:
          typeof entity.countryName === 'string' ||
          entity.countryName instanceof String
            ? '90'
            : entity.countryName && entity.countryName.confidence
            ? entity.countryName.confidence
            : '90',
        value:
          typeof entity.countryName === 'string' ||
          entity.countryName instanceof String
            ? entity.countryName
            : entity.countryName && entity.countryName.value
            ? entity.countryName.value
            : '',
      },
      dateOfBirth: {
        confidence:
          typeof entity.dateOfBirth === 'string' ||
          entity.dateOfBirth instanceof String
            ? '90'
            : entity.dateOfBirth && entity.dateOfBirth.confidence
            ? entity.dateOfBirth.confidence
            : '90',
        value:
          typeof entity.dateOfBirth === 'string' ||
          entity.dateOfBirth instanceof String
            ? changeDobFormate(entity.dateOfBirth)
            : entity.dateOfBirth && entity.dateOfBirth.value
            ? changeDobFormate(entity.dateOfBirth.value)
            : '',
      },
      expiryDate: {
        confidence:
          typeof entity.expiryDate === 'string' ||
          entity.expiryDate instanceof String
            ? '90'
            : entity.expiryDate && entity.expiryDate.confidence
            ? entity.expiryDate.confidence
            : '90',
        value:
          typeof entity.expiryDate === 'string' ||
          entity.expiryDate instanceof String
            ? formateDate(entity.expiryDate)
            : entity.expiryDate && entity.expiryDate.value
            ? formateDate(entity.expiryDate.value)
            : '',
      },
      firstName: {
        confidence:
          typeof entity.firstName === 'string' ||
          entity.firstName instanceof String
            ? '90'
            : entity.firstName && entity.firstName.confidence
            ? entity.firstName.confidence
            : '90',
        value:
          typeof entity.firstName === 'string' ||
          entity.firstName instanceof String
            ? entity.firstName
            : entity.firstName && entity.firstName.value
            ? entity.firstName.value
            : '',
      },
      gender: {
        confidence:
          typeof entity.gender === 'string' || entity.gender instanceof String
            ? '90'
            : entity.gender && entity.gender.confidence
            ? entity.gender.confidence
            : '90',
        value:
          typeof entity.gender === 'string' || entity.gender instanceof String
            ? entity.gender
            : entity.gender && entity.gender.value
            ? entity.gender.value
            : '',
      },
      lastName: {
        confidence:
          typeof entity.lastName === 'string' ||
          entity.lastName instanceof String
            ? '90'
            : entity.lastName && entity.lastName.confidence
            ? entity.lastName.confidence
            : '90',
        value:
          typeof entity.lastName === 'string' ||
          entity.lastName instanceof String
            ? entity.lastName
            : entity.lastName && entity.lastName.value
            ? entity.lastName.value
            : '',
      },
      middleName: { confidence: '', value: '' },
      nationality: {
        confidence:
          typeof entity.userNationality === 'string' ||
          entity.userNationality instanceof String
            ? '90'
            : entity.userNationality && entity.userNationality.confidence
            ? entity.userNationality.confidence
            : '90',
        value:
          typeof entity.userNationality === 'string' ||
          entity.userNationality instanceof String
            ? entity.userNationality
            : entity.userNationality && entity.userNationality.value
            ? entity.userNationality.value
            : '',
      },
      passportNumber: {
        confidence:
          typeof entity.passportNumber === 'string' ||
          entity.passportNumber instanceof String
            ? '90'
            : entity.passportNumber && entity.passportNumber.confidence
            ? entity.passportNumber.confidence
            : '90',
        value:
          typeof entity.passportNumber === 'string' ||
          entity.passportNumber instanceof String
            ? entity.passportNumber
            : entity.passportNumber && entity.passportNumber.value
            ? entity.passportNumber.value
            : '',
      },
    });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getParsingResume = (entity) => {
    setResumeInfo((pre) => ({
      ...pre,
      [284]: getWorkingValues(entity),
      [474]: educationValues(entity),
    }));
  };
  const educationValues = (edu) => {
    let education = [];
    if (
      edu.hasOwnProperty('EducationOrganizationAttendance') &&
      edu.EducationOrganizationAttendance.length
    ) {
      education = edu.EducationOrganizationAttendance.map(
        (val) =>
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${val.MajorProgramName} ${val.School ? 'in ' : ''}${val.School}`,
      );
    }
    return education && education.length ? education : [''];
  };
  const getWorkingValues = (working) => {
    let work = [];
    if (
      working.hasOwnProperty('PositionHistory') &&
      working.PositionHistory.length
    ) {
      work = working.PositionHistory.map(
        (val, index) =>
          `${index !== 0 ? (val ? '\n' : '') : ''}${
            val.Role ? val.Role + ' in ' : ''
          }${val.Company}${val.Company ? ',' : ''}${val.StartDate}${
            val.EndDate ? '-' : ''
          }${val.EndDate}`,
      );
    }
    return work.toString();
  };
  const bodyFormData = useCallback(
    async (docData: any, preViewCheck?: boolean) => {
      let docformdata: any;

      if (!preViewCheck) {
        docformdata = new FormData();
        docformdata.append('file', copyFile);
        docformdata.append('documentData', JSON.stringify(docData));
        docformdata.append(
          'documentSelectedId',
          fileUploadOpen?.documentInfo?.currentDocumentId
            ? fileUploadOpen.documentInfo.currentDocumentId
            : null,
        );
        docformdata.append('documentFor', 'lead');
        docformdata.append('submitted', preview ? 'true' : 'false');
      } else {
        docformdata = {
          documentData: JSON.stringify(docData),
          documentFor: 'lead',
          documentId: fileUploadOpen?.editDocId,
          documentSelectedId: fileUploadOpen?.documentInfo?.currentDocumentId
            ? fileUploadOpen.documentInfo.currentDocumentId
            : null,
          submitted: preview,
        };
      }
      return await docformdata;
    },
    [
      fileUploadOpen.documentInfo?.currentDocumentId,
      fileUploadOpen?.editDocId,
      preview,
      copyFile,
    ],
  );
  const getPassportDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const passportValues: any = await instantState(setPassportValues);
      const docData = {
        ...passportValues,
        dateOfBirth: {
          confidence: passportValues.dateOfBirth.confidence,
          value: changeDobFormate(passportValues.dateOfBirth.value),
        },
        gender: {
          confidence: passportValues.gender.confidence,
          value: getGenderValues(passportValues.gender.value),
        },
      };
      return await bodyFormData(docData, previewCheck);
    },
    [bodyFormData],
  );
  const getResumeDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const arr = [];
      const resumeInfo: any = await instantState(setResumeInfo);
      arr.push({
        answer: JSON.stringify(resumeInfo[474].filter((e) => e)),
        id: 474,
      });
      arr.push({ answer: resumeInfo[284], id: 284 });
      return await bodyFormData(arr, previewCheck);
    },
    [bodyFormData],
  );
  const getLcaDefaultFormData = useCallback(
    async (previewCheck?: boolean) => {
      const finalArr = [];
      const parsingData: any = await instantState(setParsingData);
      parsingData.map((val: any) => {
        if (val.questionId) {
          if (val.response_type === 5 || val.response_type === 6) {
            val.answer =
              val.multiple_pdf_field && val.multiple_pdf_field.length
                ? JSON.stringify(val.multiple_pdf_field)
                : JSON.stringify([]);
          }
          finalArr.push(val);
        }
      });
      return await bodyFormData(finalArr, previewCheck);
    },
    [bodyFormData],
  );
  const uploadCorrect = useCallback(async () => {
    if (fileUploadOpen?.documentInfo?.parsing_type === 'passport') {
      const formdata = await getPassportDefaultFormData();
      await createUploadDocuments(formdata);
      return;
    } else if (fileUploadOpen?.documentInfo?.parsing_type === 'resume') {
      const formdata = await getResumeDefaultFormData();
      await createUploadDocuments(formdata);
      return;
    } else if (
      fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
      fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
      fileUploadOpen?.documentInfo?.parsing_type === 'i-140'
    ) {
      const formdata = await getLcaDefaultFormData();
      await createUploadDocuments(formdata);
      return;
    }
  }, [
    createUploadDocuments,
    fileUploadOpen?.documentInfo?.parsing_type,
    getLcaDefaultFormData,
    getPassportDefaultFormData,
    getResumeDefaultFormData,
  ]);

  const getI_129DefaultFormData = useCallback(
    (formExtractionId, file) => {
      const docformdata = new FormData();
      docformdata.append('file', file);
      docformdata.append(
        'documentSelectedId',
        fileUploadOpen?.documentInfo?.currentDocumentId,
      );
      docformdata.append('documentData', JSON.stringify([]));
      docformdata.append('documentFor', 'lead');
      docformdata.append('formExtractionId', formExtractionId);
      return docformdata;
    },
    [fileUploadOpen?.documentInfo?.currentDocumentId],
  );
  const i_129PrsingDefaultDocValues = useCallback(
    (formExtractionId, file) => {
      const i129Values = getI_129DefaultFormData(formExtractionId, file);
      createUploadDocuments(i129Values, 'dialogNotClose');
    },
    [createUploadDocuments, getI_129DefaultFormData],
  );

  const getReferenceValues = useCallback(
    (parseId: any) => {
      return {
        beneficieryName: location.state?.data?.name,
        formExtractionId: parseId.formExtractionId
          ? parseId.formExtractionId
          : '',
        leadUserId: location.state?.data?.leadUserId || editId,
        // petitionId: location.state?.data?.leadUserId || editId,
      };
    },
    [editId, location.state?.data?.leadUserId, location.state?.data?.name],
  );
  const refrencekeySaveI_129ParsingDefaultDoc = useCallback(
    async (result: any, file: any) => {
      try {
        const reference = getReferenceValues(result);
        const orgId =
          userDetail.userRoleName === UserRoleType.Beneficiary ||
          userDetail.userRoleName === UserRoleType.Petitioner
            ? leadInfo && leadInfo.hasOwnProperty('attorney_assigned')
              ? leadInfo.attorney_assigned
              : ''
            : location.state && location.state?.user === 'petitioner'
            ? location.state?.data?.personId
            : '';
        await addNewData(
          reference,
          COMMON_ENDPOINT.uploadDocumentI_129RefrenceIdSave(
            orgId,
            fileUploadOpen.roleId,
            leadInfo && leadInfo.attorney_contact_id
              ? leadInfo.attorney_contact_id
              : '',
          ),
        );
        i_129PrsingDefaultDocValues(
          result && result.formExtractionId ? result.formExtractionId : '',
          file,
        ); // Addition Udation Default DOc values
      } catch (error) {
        i_129PrsingDefaultDocValues(
          result && result.formExtractionId ? result.formExtractionId : '',
          file,
        ); // Addition Udation Default DOc values
      }
    },
    [
      fileUploadOpen.roleId,
      getReferenceValues,
      i_129PrsingDefaultDocValues,
      leadInfo,
      location.state,
      userDetail.userRoleName,
    ],
  );
  const getLcaActualQuestionAndAnswer = useCallback(
    async (array: Array<any>) => {
      try {
        const parsingResponse = await addNewData(
          { list: array, type: fileUploadOpen?.documentInfo?.parsing_type },
          COMMON_ENDPOINT.uploadDocumentGetParsingValue,
        );
        const parsingVal =
          parsingResponse && parsingResponse.data ? parsingResponse.data : [];
        setParsingData(parsingVal);
        if (
          parsingVal.length === 0 ||
          !parsingVal.some((val) => val.questionId)
        ) {
          uploadCorrect();
          return;
        }
        if (
          userDetail?.userRoleName === UserRoleType.Beneficiary ||
          userDetail?.userRoleName === UserRoleType.Petitioner
        ) {
          uploadCorrect();
          return;
        }
        setPreview(true);
      } catch (error) {
        if (
          userDetail?.userRoleName === UserRoleType.Beneficiary ||
          userDetail?.userRoleName === UserRoleType.Petitioner
        ) {
          uploadCorrect();
          return;
        }
        setLoader(false);
        toast.error('Values not parsed properly', alertOptions);
      }
    },
    [
      fileUploadOpen?.documentInfo?.parsing_type,
      uploadCorrect,
      userDetail?.userRoleName,
    ],
  );
  const mappingValues = useCallback(
    (arr: Array<any>) => {
      const lcaParsingValues = arr.map((val) => {
        const finalArr = [];
        if (val[1]) {
          const obj: object = val[1];
          for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
              const originalObj = {
                answer: '',
                keyValue: '',
              };
              const element = obj[key];
              originalObj.keyValue = key;
              originalObj.answer = element;
              if (element) {
                finalArr.push(originalObj);
              }
            }
          }
        }
        val[1] = finalArr;
        return val[1] && val[1].length ? val[1] : [];
      });
      const newArry = [].concat(...lcaParsingValues);
      if (newArry && newArry.length) {
        getLcaActualQuestionAndAnswer(newArry);
      } else {
        uploadCorrect();
      }
    },
    [getLcaActualQuestionAndAnswer, uploadCorrect],
  );

  const parsingDocuments = useCallback(
    async (file: any) => {
      resetParseDocStateValues();
      let uploadTypeCheck = '';
      if (fileUploadOpen?.documentInfo?.parsing_type) {
        uploadTypeCheck =
          fileUploadOpen?.documentInfo?.parsing_type === 'i-129'
            ? 'i129'
            : fileUploadOpen?.documentInfo?.parsing_type;
      }
      setLoader(true);
      try {
        const uploadType = await instantState(setUploadType);
        const docformdata = new FormData();
        docformdata.append('file', file);
        docformdata.append('uploadType', uploadTypeCheck);
        docformdata.append('uploadCheck', String(uploadType));
        docformdata.append('baseUrl', BASE_URL);
        docformdata.append('beneficieryName', location.state?.data?.name);
        docformdata.append(
          'documentId',
          fileUploadOpen?.documentInfo?.currentDocumentId,
        );
        docformdata.append(
          'petitionId',
          location.state?.data?.leadUserId || editId,
        );
        const documentParsingResponse = await addNewData(
          docformdata,
          COMMON_ENDPOINT.uploadDocumentParsing(
            location.state?.data?.personId,
            fileUploadOpen.roleId,
            uploadTypeCheck,
          ),
        );
        const res: any = documentParsingResponse.responce;
        setLoader(false);
        setUploadType(false);
        if (res === 'Unauthorized') {
          const recallTime = await instantState(setRecallTime);
          if (recallTime < 4) {
            setUploadType(true);
            setRecallTime(Number(recallTime) + 1);
            parsingDocuments(file);
          } else {
            toast.error(
              'Could not update. Please try again after sometime',
              alertOptions,
            );
          }
          return;
        }
        setRecallTime(0);

        if (
          res &&
          res['entityResults'] &&
          res.entityResults['docClassification'] &&
          res.entityResults.docClassification === 'Unknown'
        ) {
          if (fileUploadOpen?.documentInfo?.parsing_type === 'i-129') {
            toast.error('Upload a valid I-129 PDF', alertOptions);
            return;
          }
          toast.error(
            fileUploadOpen?.documentInfo?.parsing_type === 'passport'
              ? 'Upload a valid passport'
              : fileUploadOpen?.documentInfo?.parsing_type === 'lca'
              ? 'Upload a valid LCA document'
              : 'Upload a valid document',
            alertOptions,
          );
          return;
        } else if (
          (typeof res === 'string' || res instanceof String) &&
          userDetail.userRoleName === UserRoleType.Attorney
        ) {
          const errorRes: any = res;
          toast.error(
            errorRes ? errorRes : 'Something went wrong',
            alertOptions,
          );
          return;
        }

        if (fileUploadOpen?.documentInfo?.parsing_type === 'passport') {
          if (
            res.results &&
            (res.results['firstName'] ||
              res.results['lastName'] ||
              res.results['gender'] ||
              res.results['dateOfBirth'] ||
              res.results['userNationality'] ||
              res.results['countryName'] ||
              res.results['passportNumber'] ||
              res.results['expiryDate']) &&
            res.results.firstName
          ) {
            getPassportPaesingDoc(res.results);
            if (
              userDetail.userRoleName === UserRoleType.Beneficiary ||
              userDetail.userRoleName === UserRoleType.Petitioner
            ) {
              uploadCorrect();
              return;
            }
          } else {
            uploadCorrect();
            return;
          }
        } else if (fileUploadOpen?.documentInfo?.parsing_type === 'resume') {
          if (
            res.entityResults &&
            (res.entityResults.hasOwnProperty(
              'EducationOrganizationAttendance',
            ) ||
              res.entityResults.hasOwnProperty('PositionHistory'))
          ) {
            getParsingResume(res.entityResults);
            if (
              userDetail.userRoleName === UserRoleType.Beneficiary ||
              userDetail.userRoleName === UserRoleType.Petitioner
            ) {
              uploadCorrect();
              return;
            }
          } else {
            uploadCorrect();
            return;
          }
        } else if (
          fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
          fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
          fileUploadOpen?.documentInfo?.parsing_type === 'i-140'
        ) {
          const lcaArr =
            res.extractionResults && res?.extractionResults
              ? Object.entries(res.extractionResults)
              : [];
          if (!lcaArr || lcaArr.length === 0) {
            uploadCorrect();
            return;
          }
          mappingValues(lcaArr);
          return;
        } else if (fileUploadOpen?.documentInfo?.parsing_type === 'i-129') {
          setLoader(true);
          refrencekeySaveI_129ParsingDefaultDoc(res, file);
          return;
        }
        setPreview(true);
        setFileUploadOpen((pre) => ({
          ...pre,
          open: false,
        }));
      } catch (error) {
        setLoader(false);
        if (
          userDetail.userRoleName === UserRoleType.Beneficiary ||
          userDetail.userRoleName === UserRoleType.Petitioner
        ) {
          uploadCorrect();
          return;
        }
        // toast.error(error ? String(error) : '', alertOptions);
      }
    },
    [
      editId,
      fileUploadOpen?.documentInfo?.currentDocumentId,
      fileUploadOpen?.documentInfo?.parsing_type,
      fileUploadOpen.roleId,
      getParsingResume,
      location.state?.data?.leadUserId,
      location.state?.data?.name,
      location.state?.data?.personId,
      mappingValues,
      refrencekeySaveI_129ParsingDefaultDoc,
      uploadCorrect,
      userDetail.userRoleName,
    ],
  );
  const uploadDoc = useCallback(
    (file) => {
      if (file) {
        const objectURL = URL.createObjectURL(file);
        setFileSrc(objectURL);
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'documentSelectedId',
          fileUploadOpen?.documentInfo?.currentDocumentId
            ? fileUploadOpen.documentInfo.currentDocumentId
            : null,
        );
        formData.append('documentFor', 'lead');
        if (
          parsingTypeArr.includes(fileUploadOpen?.documentInfo?.parsing_type)
        ) {
          parsingDocuments(file);
          return;
        }
        setLoader(true);
        if (fileUploadOpen?.editDocId) {
          editUploadDocuments(formData);
        } else {
          createUploadDocuments(formData);
        }
      }
    },
    [
      createUploadDocuments,
      editUploadDocuments,
      fileUploadOpen.documentInfo?.currentDocumentId,
      fileUploadOpen.documentInfo?.parsing_type,
      fileUploadOpen?.editDocId,
      parsingDocuments,
    ],
  );
  const deleteUploadDoc = (doc: any) => {
    setLoader(true);
    deleteData(
      doc.documentId,
      location?.state?.DependentuploadDocument === 'dependentUpload'
        ? COMMON_ENDPOINT.DependentUpload
        : COMMON_ENDPOINT.ReviewDocuments,
    )
      .then((resp: any) => {
        toast.success('File deleted successfully', alertOptions);
        setConfirmOpen({
          doc: {},
          index: null,
          show: false,
        });
        getList();
      })
      .catch((e) => {
        toast.error(e ? e : 'Oops!!! Something went wrong', alertOptions);
        setLoader(false);
      });
  };

  const viewFileDoc = (file: string, mimeType?: string) => {
    const filePathUrl = `${String(
      process.env.REACT_APP_FILE_URL,
    )}0?key=${file}&contentType=${mimeType}`;

    setOpenReview({
      fileType: mimeType,
      fileUrl: filePathUrl,
      key: file,
      open: true,
    });
  };

  const downloadFileDoc = (file: string) => {
    const obj = {
      key: file,
    };
    const fileName = file.split('/').pop();
    setLoader(true);
    getS3file(obj, COMMON_ENDPOINT.AWSS3FILES)
      .then((data: any) => {
        setLoader(false);
        saveAs(data, fileName);
        toast.success(`${fileName} Downloaded`, alertOptions);
      })
      .catch((err) => {
        setLoader(false);
        toast.error(err ? err : 'Oops!!! Something went wrong', alertOptions);
      });
  };
  const selectAllDocument = (ele) => {
    setCopyDocumentSection((pre) => ({
      ...pre,
      data: [
        ...pre.data.map((e) => {
          return {
            ...e,
            multipleDocumentsList: [
              ...e.multipleDocumentsList.map((multi) => ({
                ...multi,
                checked: ele?.target.checked,
              })),
            ],
          };
        }),
      ],
    }));
  };

  const getBeneficiaryList = useCallback((leadId, leadUserId) => {
    getAllListData(
      `${COMMON_ENDPOINT.RequestDocument}/${String(leadId)}/${String(
        leadUserId,
      )}?addFor=4&docfor=lead`,
    ).then((resp: any) => {
      setBeneficiaryDocuments(resp);
      setLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDependentList = useCallback(() => {
    getAllListData(
      `${COMMON_ENDPOINT.DependentUpload}/${String(
        location?.state?.dependentUserId,
      )}/${String(editId)}`,
    ).then((resp: any) => {
      setDependentDocuments(resp);
      setLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dependentUploadDoc = (file) => {
    setLoader(true);
    const formData = new FormData();

    formData.append('file', file);
    formData.append('petitionId', String(editId));
    formData.append(
      'documentId',
      fileUploadOpen?.documentInfo?.currentDocumentId,
    );

    addNewData(
      formData,
      COMMON_ENDPOINT.dependentUploadDocumnet(location?.state?.dependentUserId),
    )
      .then(() => {
        getList();
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
        toast.error('Something went wrong', alertOptions);
      });
  };
  const dependentEditUploadDocuments = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    try {
      await updateData(
        '',
        formData,
        COMMON_ENDPOINT.dependentUploadDocumnet(fileUploadOpen?.editDocId),
      );
      getList();
      setFileUploadOpen((pre) => ({
        ...pre,
        documentInfo: null,
        editDocId: null,
        open: false,
        roleId: null,
      }));
    } catch (error) {
      setLoader(false);
    }
  };

  const getPetitionerList = useCallback(() => {
    getAllListData(
      `${COMMON_ENDPOINT.RequestDocument}/${String(editId)}/${String(
        editId,
      )}?addFor=2&docfor=lead`,
    ).then((resp: any) => {
      setPetitioneryDocuments(resp);
      setLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeUploadDialog = useCallback(
    (action: any) => {
      if (action) {
        if (location?.state?.DependentuploadDocument === 'dependentUpload') {
          if (fileUploadOpen?.editDocId) {
            dependentEditUploadDocuments(action);
          } else {
            dependentUploadDoc(action);
          }
        } else {
          setCopyFile(action);
          uploadDoc(action);
        }
      } else {
        setFileUploadOpen((pre) => ({
          ...pre,
          documentInfo: null,
          editDocId: null,
          open: false,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uploadDoc],
  );

  const getSkilBukDocCount = (leadId: any) => {
    getAllListData(
      `${COMMON_ENDPOINT.DocumentParse}/bulkdoc/count?id=${String(
        leadId,
      )}&uploadFor=lead`,
    )
      .then((resp) => {
        setCountDetails((pre) => ({
          ...pre,
          pending: !_.isEmpty(resp)
            ? resp.pendingCount
              ? resp.pendingCount
              : 0
            : 0,
        }));
      })
      .catch((e) => {
        toast.error(e, alertOptions);
      });
  };
  const getLeadInfo = () => {
    const leadRowId =
      userDetail && userDetail.role_id === 4
        ? searchParams.get('lead')
        : editId;
    getAllListData(
      `${COMMON_ENDPOINT.LeadList}?id=${String(
        location?.state?.DependentuploadDocument === 'dependentUpload'
          ? location?.state?.leadId
          : leadRowId,
      )}`,
    ).then((resp) => {
      setPetitonerStatus(resp.data[0].petitioner_associated);
      setPetitionerId(resp.data[0].petitioner_assigned);
      setLeadInfo(
        resp && resp.data && resp.data.length && resp.data[0]
          ? resp.data[0]
          : null,
      );
      if (
        userDetail.userRoleName === 'attorney' ||
        (resp.data &&
          resp.data.length &&
          resp.data[0]?.isAffilateCase &&
          resp.data[0]?.affilateRoleId === 3)
      ) {
        getSkilBukDocCount(editId);
      }
    });
  };
  const resumeOnConfirm = (type: string, info: any) => {
    if (type === 'reUpload') {
      setFileUploadOpen((pre) => ({
        ...pre,
        open: true,
      }));
      setPreview(false);
      resetParseDocStateValues();
      return;
    }
    if (type === 'submit') {
      if (fileUploadOpen?.documentInfo?.parsing_type === 'passport') {
        setPassportValues({ ...info });
      } else if (fileUploadOpen?.documentInfo?.parsing_type === 'resume') {
        setResumeInfo({ ...info });
      } else if (
        fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
        fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
        fileUploadOpen?.documentInfo?.parsing_type === 'i-140'
      ) {
        setParsingData([...info]);
      }
      uploadCorrect();
      return;
    }
    setFileUploadOpen((pre) => ({
      ...pre,
      open: false,
    }));
    resetParseDocStateValues();
    setPreview(false);
  };
  const resetParseDocStateValues = () => {
    setPassportValues({ ...passportDefaultValues });
    setResumeInfo({ ...resumeDefaultValues });
    setParsingData([]);
  };
  const parsedValuesView = (
    parsingTypeCheck,
    view,
    documentId,
    roleId,
    checking?,
  ) => {
    if (view && view.is_parsing_value_submitted) {
      toast.info(
        'Parsing information already reviewed and submitted.',
        alertOptions,
      );
      return;
    }
    if (parsingTypeCheck === 'i-129') {
      if (
        view &&
        view.from_bulk_document_parsing &&
        view.document_data &&
        Object.keys(view.document_data).length
      ) {
        navigate(
          `${AttorneyPrivateRoutes.DOCUMENTI129PREVIEW}/${String(
            view.documentId,
          )}`,
        );
        return;
      } else if (
        view &&
        view.from_bulk_document_parsing &&
        (!view.document_data || !Object.keys(view.document_data).length)
      ) {
        toast.info(
          'Parsing data yet to be updated. Please check back later.',
          alertOptions,
        );
        return;
      } else if (
        view &&
        !view.from_bulk_document_parsing &&
        view.form_extraction_id &&
        view.document_data &&
        Object.keys(view.document_data)
      ) {
        navigate(
          `${AttorneyPrivateRoutes.DOCUMENTI129PREVIEW}/${String(
            view.documentId,
          )}`,
        );
        return;
      } else if (
        view &&
        !view.from_bulk_document_parsing &&
        view.form_extraction_id &&
        (!view.document_data || !Object.keys(view.document_data).length)
      ) {
        toast.info(
          'Parsing data yet to be updated. Please check back later.',
          alertOptions,
        );
        return;
      } else {
        toast.info(
          'Parsing data yet to be updated. Please check back later.',
          alertOptions,
        );
        return;
      }
    }
    if (
      view &&
      (!view.document_data ||
        (Array.isArray(view.document_data) && !view.document_data.length))
    ) {
      toast.info(
        'Parsing data yet to be updated. Please check back later.',
        alertOptions,
      );
      return;
    }
    navigate(
      `${AttorneyPrivateRoutes.DOCUMENTPREVIEW}/${String(view.documentId)}`,
    );
    return;
  };
  const checkToken = useCallback(async () => {
    if (toast) {
      toast.error('Bulk Upload is not available');
      return;
    }
    const uploadCheck = await instantState(setUploadCheck);
    const doc = {
      documentFor: 'lead',
      leadId: editId ? parseInt(editId) : '',
      uploadCheck: uploadCheck,
      uploadForId: '',
      uploadedFor: '',
    };
    setLoader(true);
    await addNewData(doc, `${COMMON_ENDPOINT.BulkDocumentUpload}/review`)
      .then((res) => {
        if (res === 'Unauthorized') {
          setUploadCheck(true);
          checkToken();
          return;
        }
        setUploadCheck(false);
        // setIsDocumentReview({ reviewDoc: true, token: res.data });
        setLoader(false);
        // isDocumentReview['reviewDoc'] = true;
        // isDocumentReview['token'] = res.data;
        navigate(`${AttorneyPrivateRoutes.LEADS.REVIEWDOCUMENT}/${editId}`, {
          state: {
            review: {
              reviewDoc: true,
              token: `${res.data}&token=${
                Storage.getTokens().token
              }&envName=${String(process.env.REACT_APP_ENV)}`,
            },
          },
        });
      })
      .catch((err) => {
        setUploadCheck(false);
        setLoader(false);
        // toast.error(err, alertOptions);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const copyDocument = () => {
    getAllListData(
      `${COMMON_ENDPOINT.CopyDocumentLead}/${String(
        petitionerId,
      )}?associated=${String(petitonerStatus)}&firmId=${String(
        debouncedParams.firmId,
      )}&visaId=${String(debouncedParams.visaId)}&documentType=${String(
        debouncedParams.documentType,
      )}&skip=&take=&uploadOnly=true`,
    ).then((resp: any) => {
      setCopyDocumentSection((pre) => ({
        ...pre,
        data: resp.data,
      }));
      setLoader(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  const uploadSelectedDoc = () => {
    const selectedDocuments: any = [];
    copyDocumentSection?.data?.map((e) => {
      e.multipleDocumentsList?.map((data) => {
        data['checked']
          ? selectedDocuments.push({
              currentDocumentId: e.currentDocumentId,
              documentPath: data.document_path,
              mimeType: data.mime_type,
              originalName: data.original_name,
            })
          : '';
      });
    });
    const payload = {
      organizationId: editId,
      roleId: 2,
      selectedDocuments: selectedDocuments,
    };

    updateData(
      '',
      payload,
      `${COMMON_ENDPOINT.CopyBeneficiary_Petitioner}/lead/${editId}`,
    )
      .then((resp: any) => {
        getList();
        setCopyDocumentSection((pre) => ({
          ...pre,
          open: false,
        }));
        toast.success('Upload successfully', alertOptions);
      })
      .catch((e) => {
        setLoader(false);
      });
  };
  const removeOrAddInMultiDoc = (list, mainIndex, subIndex, checked) => {
    list[mainIndex].multipleDocumentsList[subIndex].checked = checked;
    setCopyDocumentSection((pre) => ({
      ...pre,
      data: [...list],
    }));
  };

  const removeOrAddDoc = (currentData, checked) => {
    setCopyDocumentSection((pre) => ({
      ...pre,
      data: [
        ...pre.data.map((e) => {
          return {
            ...(e.currentDocumentId === currentData.currentDocumentId
              ? {
                  ...e,
                  multipleDocumentsList: [
                    ...e.multipleDocumentsList.map((multi) => ({
                      ...multi,
                      checked: checked,
                    })),
                  ],
                }
              : e),
          };
        }),
      ],
    }));
  };
  const checkAll = () => {
    if (copyDocumentSection?.data?.length > 0) {
      return copyDocumentSection?.data.filter((val) =>
        val.multipleDocumentsList.every((el) => el.checked === true),
      ).length === copyDocumentSection?.data.length
        ? true
        : false;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getList();
    if (location?.state?.orgCommon) {
      // Empty Blocj state,enr
    } else {
      getLeadInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (petitionerId) {
      copyDocument();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParams.documentType]);

  return (
    <Grid container>
      <Grid item md={10} sm={12} sx={{ paddingRight: '2rem' }}>
        <Box sx={{ marginTop: '1.5rem', width: '100%' }} className="uploadTab">
          {loader && <div className="liberty-loader" />}
          <>
            {location?.state?.DependentuploadDocument === 'dependentUpload' ? (
              <Typography
                component={'div'}
                className="black-white-text"
                sx={{
                  fontFamily: 'Arial-Bold !important',
                  fontSize: '1.25em !important',
                }}>
                {location?.state?.dependentName}
              </Typography>
            ) : location.pathname.includes('manage-organization') ? (
              <Typography
                component={'div'}
                className="black-white-text"
                sx={{
                  fontFamily: 'Arial-Bold !important',
                  fontSize: '1.25em !important',
                }}>
                Organization : {''}
                {location?.pathname?.includes('invited')
                  ? organizationData?.firm_name
                  : organizationData?.orgName}
              </Typography>
            ) : (
              ''
            )}
            <TabContext value={tabValue}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  style={{ marginTop: '17px' }}>
                  {(location.state?.user === 'beneficiary' ||
                    userDetail.role_id === 4) &&
                  location?.state?.DependentuploadDocument !==
                    'dependentUpload' ? (
                    <Tab label="Beneficiary" value="1" />
                  ) : location?.state?.DependentuploadDocument ===
                    'dependentUpload' ? (
                    <Tab label="Dependent" value="3" />
                  ) : location?.state?.organization ? (
                    <Tab label="Petitioner" value="4" />
                  ) : (
                    <Tab label="Petitioner" value="2" />
                  )}
                </TabList>
                {location.pathname.includes('manage-organization') && (
                  <Typography component={'div'} className="d-flex-a">
                    <Typography
                      component={'div'}
                      sx={{ width: '200px' }}
                      className="mr-1">
                      <CustomSelect
                        searchable={true}
                        labelKey={'visa_code'}
                        valueKey={'visa_id'}
                        options={!_.isEmpty(visaTypes) ? visaTypes : []}
                        onChange={(e) => {
                          setParams((prev) => ({
                            ...prev,
                            skip: 0,
                            take: 50,
                            visaId: e.target.value,
                          }));
                        }}
                        placeHolder={'Select visa type'}
                        className={'custom-search-input bo-rles'}
                      />
                    </Typography>
                    <Typography
                      component={'div'}
                      sx={{ width: '200px' }}
                      className="mr-1">
                      <CustomInput
                        placeHolder={'Type to filter'}
                        className={'custom-search-input'}
                        value={params.documentType}
                        inputProps={{
                          endAdornment:
                            params.documentType !== '' ? (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  edge="end"
                                  onClick={() => {
                                    setParams((prev) => ({
                                      ...prev,
                                      documentType: '',
                                      skip: 0,
                                      take: 50,
                                    }));
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                        }}
                        onChange={(e) => {
                          setParams((prev) => ({
                            ...prev,
                            documentType: e.target.value,
                            skip: 0,
                            take: 50,
                          }));
                        }}
                      />
                    </Typography>
                  </Typography>
                )}
                {location.state?.isBack && (
                  <Typography
                    component={'div'}
                    className=" d-flex-jae pl-2"
                    style={{
                      marginTop: '12px',
                    }}>
                    <Button
                      className="cancel-btn"
                      sx={{
                        borderRadius: '5px !important',
                        fontFamily: 'Arial-Regular !important',
                        fontSize: '0.9rem !important',
                        marginBottom: '7px',
                        width: '120px !important',
                      }}
                      onClick={() =>
                        userDetail.role_id === 3 &&
                        !location?.state?.organization
                          ? location.state?.screen === 'petition'
                            ? navigate(
                                `${
                                  AttorneyPrivateRoutes.PETITIONS
                                    .UPLOADPETITIONSDOCUMENT
                                }/${String(editId)}`,
                                {
                                  state: {
                                    tab: '4',
                                  },
                                },
                              )
                            : navigate(
                                `${
                                  AttorneyPrivateRoutes.LEADS.LEADSEDIT
                                }/${String(editId)}${
                                  searchParams.get('orgId')
                                    ? '?orgId=' + searchParams.get('orgId')
                                    : ''
                                }${
                                  searchParams.get('invited')
                                    ? '&invited=true&isLead=true'
                                    : searchParams.get('associated')
                                    ? '&associated=true&isLead=true'
                                    : ''
                                }`,
                              )
                          : userDetail.role_id === 2
                          ? location.state?.screen === 'petition'
                            ? navigate(
                                `${
                                  PetitionerPrivateRoutes.PETITIONS
                                    .UPLOADDOCUMENT
                                }/${String(editId)}`,
                                {
                                  state: {
                                    tab: '4',
                                  },
                                },
                              )
                            : navigate(
                                `${
                                  PetitionerPrivateRoutes.LEADS.LEADSEDIT
                                }/${String(editId)}`,
                              )
                          : userDetail.role_id === 5 &&
                            location.state?.screen === 'petition'
                          ? navigate(
                              `${String(
                                AffilatePrivateRoutes.ATTORNEY_PETITIONS
                                  .UPLOADPETITIONSDOCUMENT,
                              )}/${String(editId)}`,
                              {
                                state: {
                                  tab: '4',
                                },
                              },
                            )
                          : userDetail.role_id === 5 &&
                            location.state?.screen != 'petition'
                          ? navigate(
                              `${String(
                                AffilatePrivateRoutes.ATTORNEY_LEADS.LEADSEDIT,
                              )}/${String(editId)}`,
                            )
                          : userDetail.role_id === 4 &&
                            location.state?.screen === 'petition' &&
                            navigate(
                              `${String(
                                BeneficiaryPrivateRoutes.UPLOADPETITIONSDOCUMENT,
                              )}/${String(editId)}?leadUser=${String(
                                searchParams.get('lead'),
                              )}`,
                              {
                                state: {
                                  tab: '4',
                                },
                              },
                            )
                      }>
                      <Typography
                        className="o-box d-flex-a"
                        sx={{ width: 'unset !important' }}
                      />
                      <ArrowBackIcon sx={{ marginRight: '.5rem' }} />
                      Back
                    </Button>
                  </Typography>
                )}
              </Box>
              <Typography
                component={'div'}
                sx={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '0px 8px 8px 8px',
                  minHeight: '130px',
                }}>
                {(location.state?.user === 'beneficiary' ||
                  userDetail.role_id === 4) &&
                location?.state?.DependentuploadDocument !==
                  'dependentUpload' ? (
                  <TabPanel value="1">
                    <ul className="upload-ul">
                      {beneficiaryDocuments.length > 0 ? (
                        beneficiaryDocuments.map((document, index) => (
                          <li
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              setIsUploadDocs({
                                index: index,
                                show: true,
                              })
                            }>
                            <Typography
                              component={'div'}
                              className={
                                isUploadDocs.index === index &&
                                isUploadDocs.show
                                  ? document.multipleDocumentsList.length > 0
                                    ? 'blue-ul'
                                    : 'white-ul'
                                  : ''
                              }>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '15px 0',
                                  position: 'relative',
                                }}>
                                {document.multipleDocumentsList.length > 0 && (
                                  <span className="g-badge d-flex-ja">
                                    {Number(
                                      document.multipleDocumentsList.length,
                                    )}
                                  </span>
                                )}
                                <div className="u-no">{Number(index) + 1}</div>
                                <span>{document.document_type}</span>
                              </div>
                              {isUploadDocs.index === index &&
                                isUploadDocs.show &&
                                leadInfo && (
                                  <Button
                                    className="u-btn-outer"
                                    onClick={() => {
                                      if (
                                        document.parsing_type === 'i-129' &&
                                        leadInfo &&
                                        (!leadInfo.visa_type ||
                                          leadInfo.visa_type.length === 0)
                                      ) {
                                        toast.error(
                                          'Please select the visa type',
                                          alertOptions,
                                        );
                                        return;
                                      }
                                      if (
                                        document.parsing_type === 'i-129' &&
                                        leadInfo &&
                                        leadInfo.hasOwnProperty(
                                          'attorney_assigned',
                                        ) &&
                                        !leadInfo.attorney_assigned
                                      ) {
                                        toast.error(
                                          'Please select the attorney firm in the lead to review and accept the parsed data.',
                                          alertOptions,
                                        );
                                        return;
                                      }
                                      setFileUploadOpen((pre) => ({
                                        ...pre,
                                        documentInfo: document,
                                        editDocId: null,
                                        open: true,
                                        roleId: 4,
                                      }));
                                    }}>
                                    <img src={Upload} alt="upload" />
                                  </Button>
                                )}
                              {isUploadDocs.index === index &&
                              isUploadDocs.show &&
                              document.multipleDocumentsList.length > 0 ? (
                                <ul>
                                  {document.multipleDocumentsList.map(
                                    (subDoc, sIndex) => (
                                      <li key={sIndex}>
                                        <Grid
                                          container
                                          sx={{
                                            paddingLeft: '2.5rem !important',
                                            paddingRight: '2rem !important',
                                          }}>
                                          <Grid
                                            item
                                            md={5}
                                            sm={12}
                                            className="f-15"
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'nowrap',
                                              wordBreak: 'break-all',
                                            }}>
                                            {' '}
                                            <Typography
                                              component={'div'}
                                              className="udot"
                                            />
                                            <EditFileNameUploadDocuments
                                              document={subDoc}
                                              sIndex={sIndex}
                                              setLoader={setLoader}
                                              multipleDocuemnt={
                                                document.multipleDocumentsList
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            md={2}
                                            sm={3}
                                            className="text-center f-15 d-flex-ja">
                                            {' '}
                                            <Typography
                                              className="white-text"
                                              onClick={() =>
                                                viewFileDoc(
                                                  subDoc.document_path,
                                                  subDoc.mime_type,
                                                )
                                              }>
                                              <img
                                                src={View}
                                                alt="View"
                                                className="pr-1"
                                              />
                                              View
                                            </Typography>
                                          </Grid>
                                          {!parsingTypeArr.includes(
                                            document?.parsing_type,
                                          ) &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={2}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                <Typography
                                                  className="white-text"
                                                  onClick={() => {
                                                    setFileUploadOpen(
                                                      (pre) => ({
                                                        ...pre,
                                                        documentInfo: document,
                                                        editDocId:
                                                          subDoc.documentId,
                                                        open: true,
                                                        roleId: 4,
                                                      }),
                                                    );
                                                  }}>
                                                  <span className="d-flex-jae f-16">
                                                    <UploadIcon
                                                      style={{
                                                        fill: '#FFFFFF',
                                                      }}
                                                    />
                                                    Re Upload
                                                  </span>
                                                </Typography>
                                              </Grid>
                                            )}
                                          {userDetail.userRoleName ===
                                            UserRoleType.Attorney &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={2}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                {' '}
                                                <Typography
                                                  component={'div'}
                                                  className="white-text d-flex align-center"
                                                  onClick={() => {
                                                    setConfirmOpen({
                                                      doc: subDoc,
                                                      index: sIndex,
                                                      show: true,
                                                    });
                                                  }}>
                                                  <img
                                                    src={Delete}
                                                    alt="view"
                                                    className="pr-1 pb-0"
                                                  />
                                                  Delete
                                                </Typography>
                                              </Grid>
                                            )}
                                          {userDetail.userRoleName ===
                                            UserRoleType.Attorney &&
                                            parsingTypeArr.includes(
                                              document.parsing_type,
                                            ) &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={3}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                <Typography
                                                  className="white-text d-flex align-center"
                                                  onClick={() => {
                                                    parsedValuesView(
                                                      document.parsing_type,
                                                      subDoc,
                                                      document.currentDocumentId,
                                                      document.role_id,
                                                    );
                                                  }}>
                                                  <img
                                                    src={Parsed}
                                                    alt="view"
                                                    className="pr-1"
                                                  />
                                                  Parsed Values
                                                </Typography>
                                              </Grid>
                                            )}
                                        </Grid>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              ) : null}
                            </Typography>
                          </li>
                        ))
                      ) : (
                        <li>
                          {/* <span className="u-no">1</span> */}
                          No Record(s) Found.
                        </li>
                      )}
                    </ul>
                  </TabPanel>
                ) : location?.state?.DependentuploadDocument ===
                  'dependentUpload' ? (
                  <TabPanel value="3">
                    <ul className="upload-ul">
                      {dependentDocuments && dependentDocuments.length ? (
                        dependentDocuments?.map((document, index) => (
                          <li
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              setIsUploadDocs({
                                index: index,
                                show: true,
                              })
                            }>
                            <Typography
                              component={'div'}
                              className={
                                isUploadDocs.index === index &&
                                isUploadDocs.show
                                  ? document?.multipledocumentslist?.length > 0
                                    ? 'blue-ul'
                                    : 'white-ul'
                                  : ''
                              }>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '15px 0',
                                  position: 'relative',
                                }}>
                                {document?.multipledocumentslist?.length >
                                  0 && (
                                  <span className="g-badge d-flex-ja">
                                    {Number(
                                      document.multipledocumentslist.length,
                                    )}
                                  </span>
                                )}
                                <span className="u-no">
                                  {Number(index) + 1}
                                </span>
                                <span>{document.document_type}</span>
                              </div>
                              {isUploadDocs.index === index &&
                                isUploadDocs.show &&
                                leadInfo && (
                                  <Button
                                    className="u-btn-outer"
                                    onClick={() => {
                                      // if (
                                      //   document.parsing_type === 'i-129' &&
                                      //   leadInfo &&
                                      //   (!leadInfo.visa_type ||
                                      //     leadInfo.visa_type.length === 0)
                                      // ) {
                                      //   toast.error(
                                      //     'Please select the visa type',
                                      //     alertOptions,
                                      //   );
                                      //   return;
                                      // }
                                      // if (
                                      //   document.parsing_type === 'i-129' &&
                                      //   leadInfo &&
                                      //   leadInfo.hasOwnProperty(
                                      //     'attorney_assigned',
                                      //   ) &&
                                      //   !leadInfo.attorney_assigned
                                      // ) {
                                      //   toast.error(
                                      //     'Please select the attorney firm in the lead to review and accept the parsed data.',
                                      //     alertOptions,
                                      //   );
                                      //   return;
                                      // }
                                      setFileUploadOpen((pre) => ({
                                        ...pre,
                                        documentInfo: document,
                                        editDocId: null,
                                        open: true,
                                        // roleId: 99,
                                      }));
                                    }}>
                                    <img src={Upload} alt="upload" />
                                  </Button>
                                )}
                              {isUploadDocs?.index === index &&
                              isUploadDocs?.show &&
                              document?.multipledocumentslist?.length > 0 ? (
                                <ul>
                                  {document?.multipledocumentslist?.map(
                                    (subDoc, sIndex) => (
                                      <li key={sIndex}>
                                        <Grid
                                          container
                                          sx={{
                                            paddingLeft: '2.5rem !important',
                                            paddingRight: '2rem !important',
                                          }}>
                                          <Grid
                                            item
                                            md={5}
                                            sm={12}
                                            className="f-15"
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'nowrap',
                                              wordBreak: 'break-all',
                                            }}>
                                            {' '}
                                            <Typography
                                              component={'div'}
                                              className="udot"
                                            />
                                            {/* <Typography className="d-flex-1 black-white-text">
                                              {subDoc.original_name}
                                              {' by '}
                                              {subDoc.modified_by}
                                              {', '}
                                              {moment(
                                                new Date(subDoc.modified_at),
                                              ).format('MMMM DD, YYYY')}
                                            </Typography> */}
                                            <EditFileNameUploadDocuments
                                              document={subDoc}
                                              sIndex={sIndex}
                                              setLoader={setLoader}
                                              type={'dependent'}
                                              multipleDocuemnt={
                                                document.multipledocumentslist
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            md={2}
                                            sm={3}
                                            className="text-center f-15 d-flex-ja">
                                            <Typography
                                              className="white-text"
                                              onClick={() =>
                                                viewFileDoc(
                                                  subDoc.document_path,
                                                  subDoc.mime_type,
                                                )
                                              }>
                                              <img
                                                src={View}
                                                alt="View"
                                                className="pr-1"
                                              />
                                              View
                                            </Typography>
                                          </Grid>
                                          {!parsingTypeArr.includes(
                                            document?.parsing_type,
                                          ) &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={2}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                {' '}
                                                <Typography
                                                  className="white-text"
                                                  onClick={() =>
                                                    setFileUploadOpen(
                                                      (pre) => ({
                                                        ...pre,
                                                        documentInfo: document,
                                                        editDocId:
                                                          subDoc.documentId,
                                                        open: true,
                                                        // roleId: 2,
                                                      }),
                                                    )
                                                  }>
                                                  <span className="d-flex-jae f-16">
                                                    <UploadIcon
                                                      style={{
                                                        fill: '#FFFFFF',
                                                      }}
                                                    />
                                                    Re Upload
                                                  </span>
                                                </Typography>
                                              </Grid>
                                            )}
                                          {userDetail.userRoleName ===
                                            UserRoleType.Attorney &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={2}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                <Typography
                                                  className="white-text d-flex align-center"
                                                  onClick={() => {
                                                    setConfirmOpen({
                                                      doc: subDoc,
                                                      index: sIndex,
                                                      show: true,
                                                    });
                                                  }}>
                                                  <img
                                                    src={Delete}
                                                    alt="view"
                                                    className="pr-1 pb-0"
                                                  />
                                                  Delete
                                                </Typography>
                                              </Grid>
                                            )}
                                          {userDetail.userRoleName ===
                                            UserRoleType.Attorney &&
                                            parsingTypeArr.includes(
                                              document.parsing_type,
                                            ) &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={3}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                {/* <Typography
                                                    className="white-text d-flex align-center"
                                                    onClick={() => {
                                                      parsedValuesView(
                                                        document.parsing_type,
                                                        subDoc,
                                                        document.currentDocumentId,
                                                        document.role_id,
                                                      );
                                                    }}>
                                                    <img
                                                      src={Parsed}
                                                      alt="view"
                                                      className="pr-1"
                                                    />
                                                    Parsed Values
                                                   </Typography> */}
                                              </Grid>
                                            )}
                                        </Grid>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              ) : null}
                            </Typography>
                          </li>
                        ))
                      ) : (
                        <li>
                          <Typography className="d-flex content-center w-100 mt-2">
                            No Record(s) Found.
                          </Typography>
                        </li>
                      )}
                    </ul>
                  </TabPanel>
                ) : location?.state?.organization ? (
                  <TabPanel value="4">
                    <OrganizationUploadDocument
                      leadInfo={leadInfo}
                      setLoader={setLoader}
                      loader={loader}
                      viewFileDoc={viewFileDoc}
                      associated={location.state.associated}
                      params={params}
                      setParams={setParams}
                    />
                  </TabPanel>
                ) : (
                  <TabPanel value="2">
                    <ul className="upload-ul">
                      {petitioneryDocuments.length > 0 ? (
                        petitioneryDocuments.map((document, index) => (
                          <li
                            key={index}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              setIsUploadDocs({
                                index: index,
                                show: true,
                              })
                            }>
                            <Typography
                              component={'div'}
                              className={
                                isUploadDocs.index === index &&
                                isUploadDocs.show
                                  ? document.multipleDocumentsList.length > 0
                                    ? 'blue-ul'
                                    : 'white-ul'
                                  : ''
                              }>
                              <div
                                style={{
                                  display: 'flex',
                                  padding: '15px 0',
                                  position: 'relative',
                                }}>
                                {document.multipleDocumentsList.length > 0 && (
                                  <span className="g-badge d-flex-ja">
                                    {Number(
                                      document.multipleDocumentsList.length,
                                    )}
                                  </span>
                                )}
                                <span className="u-no">
                                  {Number(index) + 1}
                                </span>
                                <span>{document.document_type}</span>
                              </div>
                              {isUploadDocs.index === index &&
                                isUploadDocs.show &&
                                leadInfo && (
                                  <Button
                                    className="u-btn-outer"
                                    onClick={() => {
                                      if (
                                        document.parsing_type === 'i-129' &&
                                        leadInfo &&
                                        (!leadInfo.visa_type ||
                                          leadInfo.visa_type.length === 0)
                                      ) {
                                        toast.error(
                                          'Please select the visa type',
                                          alertOptions,
                                        );
                                        return;
                                      }
                                      if (
                                        document.parsing_type === 'i-129' &&
                                        leadInfo &&
                                        leadInfo.hasOwnProperty(
                                          'attorney_assigned',
                                        ) &&
                                        !leadInfo.attorney_assigned
                                      ) {
                                        toast.error(
                                          'Please select the attorney firm in the lead to review and accept the parsed data.',
                                          alertOptions,
                                        );
                                        return;
                                      }
                                      setFileUploadOpen((pre) => ({
                                        ...pre,
                                        documentInfo: document,
                                        editDocId: null,
                                        open: true,
                                        roleId: 2,
                                      }));
                                    }}>
                                    <img src={Upload} alt="upload" />
                                  </Button>
                                )}
                              {isUploadDocs.index === index &&
                              isUploadDocs.show &&
                              document.multipleDocumentsList.length > 0 ? (
                                <ul>
                                  {document.multipleDocumentsList.map(
                                    (subDoc, sIndex) => (
                                      <li key={sIndex}>
                                        <Grid
                                          container
                                          sx={{
                                            paddingLeft: '2.5rem !important',
                                            paddingRight: '2rem !important',
                                          }}>
                                          <Grid
                                            item
                                            md={5}
                                            sm={12}
                                            className="f-15"
                                            sx={{
                                              display: 'flex',
                                              flexWrap: 'nowrap',
                                              wordBreak: 'break-all',
                                            }}>
                                            {' '}
                                            <Typography
                                              component={'div'}
                                              className="udot"
                                            />
                                            {/* <Typography className="d-flex-1 black-white-text">
                                              {subDoc.original_name}
                                              {' by '}
                                              {subDoc.modified_by}
                                              {', '}
                                              {moment(
                                                new Date(subDoc.modified_at),
                                              ).format('MMMM DD, YYYY')}
                                            </Typography> */}
                                            <EditFileNameUploadDocuments
                                              document={subDoc}
                                              sIndex={sIndex}
                                              setLoader={setLoader}
                                              multipleDocuemnt={
                                                document.multipleDocumentsList
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            md={2}
                                            sm={3}
                                            className="text-center f-15 d-flex-ja">
                                            <Typography
                                              className="white-text"
                                              onClick={() =>
                                                viewFileDoc(
                                                  subDoc.document_path,
                                                  subDoc.mime_type,
                                                )
                                              }>
                                              <img
                                                src={View}
                                                alt="View"
                                                className="pr-1"
                                              />
                                              View
                                            </Typography>
                                          </Grid>
                                          {!parsingTypeArr.includes(
                                            document?.parsing_type,
                                          ) &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={2}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                {' '}
                                                <Typography
                                                  className="white-text"
                                                  onClick={() =>
                                                    setFileUploadOpen(
                                                      (pre) => ({
                                                        ...pre,
                                                        documentInfo: document,
                                                        editDocId:
                                                          subDoc.documentId,
                                                        open: true,
                                                        roleId: 2,
                                                      }),
                                                    )
                                                  }>
                                                  <span className="d-flex-jae f-16">
                                                    <UploadIcon
                                                      style={{
                                                        fill: '#FFFFFF',
                                                      }}
                                                    />
                                                    Re Upload
                                                  </span>
                                                </Typography>
                                              </Grid>
                                            )}
                                          {userDetail.userRoleName ===
                                            UserRoleType.Attorney &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={2}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                <Typography
                                                  className="white-text d-flex align-center"
                                                  onClick={() => {
                                                    setConfirmOpen({
                                                      doc: subDoc,
                                                      index: sIndex,
                                                      show: true,
                                                    });
                                                  }}>
                                                  <img
                                                    src={Delete}
                                                    alt="view"
                                                    className="pr-1 pb-0"
                                                  />
                                                  Delete
                                                </Typography>
                                              </Grid>
                                            )}
                                          {userDetail.userRoleName ===
                                            UserRoleType.Attorney &&
                                            parsingTypeArr.includes(
                                              document.parsing_type,
                                            ) &&
                                            leadInfo &&
                                            !leadInfo.isAffilateCase && (
                                              <Grid
                                                md={3}
                                                sm={3}
                                                className="text-center f-15 d-flex-ja">
                                                <Typography
                                                  className="white-text d-flex align-center"
                                                  onClick={() => {
                                                    parsedValuesView(
                                                      document.parsing_type,
                                                      subDoc,
                                                      document.currentDocumentId,
                                                      document.role_id,
                                                    );
                                                  }}>
                                                  <img
                                                    src={Parsed}
                                                    alt="view"
                                                    className="pr-1"
                                                  />
                                                  Parsed Values
                                                </Typography>
                                              </Grid>
                                            )}
                                        </Grid>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              ) : null}
                            </Typography>
                          </li>
                        ))
                      ) : (
                        <li>
                          {/* <span className="u-no">1</span> */}
                          No Record(s) Found.
                        </li>
                      )}
                    </ul>
                  </TabPanel>
                )}
              </Typography>
            </TabContext>

            {openReview?.open && (
              <CustomModalView
                open={openReview?.open}
                fullWidth={true}
                maxWidth={'lg'}
                scroll={'paper'}
                close={() =>
                  setOpenReview({
                    fileType: '',
                    fileUrl: '',
                    key: '',
                    open: false,
                  })
                }
                onFileDownloadIcon={() => downloadFileDoc(openReview.key)}
                title="View Document">
                <DialogContent>
                  <Typography
                    className="assem-box"
                    id="parentdocId"
                    sx={{
                      minHeight: '30vh',
                    }}>
                    <DocumentViewer
                      fileBlob={openReview.fileUrl}
                      fileType={openReview.fileType}
                      width={
                        document.getElementById('parentdocId') &&
                        document.getElementById('parentdocId').offsetWidth
                          ? `${
                              document.getElementById('parentdocId')
                                .offsetWidth - 100
                            }px`
                          : 'auto'
                      }
                      applyBorder={true}
                    />
                  </Typography>
                </DialogContent>
              </CustomModalView>
            )}

            {fileUploadOpen.open && (
              <FileUploadDialog
                open={fileUploadOpen.open}
                allowedFileType={fileTypeCheckSkillParsing}
                errorMessage={
                  'You can upload documents of types JPEG, TIFF, PDF, XLS, XLSX, DOCX and DOC only'
                }
                info={
                  '(You can upload documents of types JPEG, TIFF, PDF, XLS, XLSX, DOCX and DOC only.You can upload upto a maximum of 90MB per upload)'
                }
                onConfirm={closeUploadDialog}
                isParsingDoc={parsingTypeArr.includes(
                  fileUploadOpen.documentInfo?.parsing_type,
                )}
                setCopyFile={setCopyFile}
                setFileUploadOpen={setFileUploadOpen}
                loader={loader}
              />
            )}
            {preview && (
              <CustomModalView
                open={true}
                fullWidth={true}
                maxWidth={'xl'}
                scroll={'paper'}
                autoCloseDisable={true}
                close={() => setPreview(false)}>
                {fileUploadOpen?.documentInfo?.parsing_type === 'resume' && (
                  <ResumeParsing
                    fileUrl={fileSrc}
                    resumeInfo={resumeInfo}
                    onConfirm={resumeOnConfirm}
                    setResumeInfo={setResumeInfo}
                    fileType={
                      copyFile &&
                      copyFile.type &&
                      copyFile.type.split('/')[1] === 'pdf'
                        ? 'pdf'
                        : copyFile?.type
                    }
                  />
                )}

                {fileUploadOpen?.documentInfo?.parsing_type === 'passport' && (
                  <PassportParsing
                    fileUrl={fileSrc}
                    passportInfo={passportValues}
                    onConfirm={resumeOnConfirm}
                    setPassportInfo={setPassportValues}
                    fileType={
                      copyFile &&
                      copyFile.type &&
                      copyFile.type.split('/')[1] === 'pdf'
                        ? 'pdf'
                        : copyFile?.type
                    }
                  />
                )}

                {(fileUploadOpen?.documentInfo?.parsing_type === 'lca' ||
                  fileUploadOpen?.documentInfo?.parsing_type === 'g-28' ||
                  fileUploadOpen?.documentInfo?.parsing_type === 'i-140') && (
                  <PdfParsing
                    fileUrl={fileSrc}
                    parseInfo={parsingData}
                    onConfirm={resumeOnConfirm}
                    setParseInfo={setParsingData}
                    fileType={
                      copyFile &&
                      copyFile.type &&
                      copyFile.type.split('/')[1] === 'pdf'
                        ? 'pdf'
                        : copyFile?.type
                    }
                  />
                )}
              </CustomModalView>
            )}
            {tempI_129 && (
              <CustomModalView open={true} close={() => setTempI_129(false)}>
                <DialogContent>
                  <div className="tempI129">
                    <section>
                      <img className="logo" src={Success} alt="complete" />
                      <h5 className="card-title" style={{ margin: 'unset ' }}>
                        <span> File Uploaded </span>
                      </h5>
                    </section>
                    <p>
                      We are working on processing the I-129 form. This will
                      take few minutes to process the complete file and fetch
                      the details from I-129 which can be pushed into the
                      current petition. You can continue with your other
                      activities while the file is being processed in the
                      background. You will be notified once it is completed and
                      you can click on the notification to edit/modify the data
                      we retrieved from I-129.
                    </p>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    className="n-add-btn"
                    sx={{
                      borderRadius: '5px !important',
                      float: 'right',
                      fontFamily: 'Arial-Regular !important',
                      fontSize: '0.9em !important',
                      height: '35px !important',
                      margin: '15px',
                      width: '149px !important',
                    }}
                    onClick={() => setTempI_129(false)}>
                    {'Ok'}
                  </Button>
                </DialogActions>
              </CustomModalView>
            )}
          </>
        </Box>

        <ConfirmDialog
          title="DELETE"
          floatingBtnShow={true}
          open={confirmOpen.show}
          onConfirm={(act) =>
            act === 'yes'
              ? deleteUploadDoc(confirmOpen.doc)
              : setConfirmOpen({
                  doc: {},
                  index: null,
                  show: false,
                })
          }
          autoCloseDisable={true}>
          Are you sure do you want to delete?
        </ConfirmDialog>
      </Grid>
      {!location.pathname.includes('manage-organization') && (
        <Grid item md={2} sm={12}>
          <Typography component={'div'}>
            <Typography component={'div'} className="bulk-add-box">
              <Typography component={'div'} className="add-inner mb-2">
                <Typography component={'div'} className="add-inner-box" />
                <Typography
                  component={'div'}
                  marginTop="19px"
                  className=" d-flex-a bu-color pl-2 pt-2 pb-1">
                  <img
                    src={Bupload}
                    alt="im"
                    className="mr-1"
                    style={{ width: '20px !important' }}
                  />
                  Bulk Upload
                </Typography>
                <Typography className=" bu-color f-16 pb-2 pl-2 l-y">
                  Simply upload any documents that might be relevant, and I’ll
                  see what information I can get, saving you even more time.
                </Typography>
              </Typography>
              <FileBulkUploadDialog
                visaId={
                  leadInfo &&
                  leadInfo.visa_type &&
                  leadInfo.visa_type.length > 0
                    ? leadInfo.visa_type[0]
                    : ''
                }
                petitionId={''}
                leadId={
                  searchParams.get('leadUser') !== '' &&
                  userDetail.role_id === 4
                    ? searchParams.get('lead')
                    : editId
                }
                roleId={tabValue === '1' ? 4 : 2}
                userName={location.state?.data?.name}
                personId={location.state?.data?.leadUserId || editId}
              />
              {userDetail.userRoleName === UserRoleType.Attorney &&
                tabValue === '2' && (
                  <Typography
                    component={'div'}
                    className="add-inner mt-2 mb-2 pointer"
                    onClick={() => {
                      setCopyDocumentSection((pre) => ({
                        ...pre,
                        open: true,
                      }));
                      copyDocument();
                    }}>
                    <Typography component={'div'} className="add-inner-box" />
                    <Typography className=" d-flex-a bu-color pl-2 pt-2 pb-1">
                      <img
                        src={Bupload}
                        alt="im"
                        className="mr-1"
                        style={{ width: '20px !important' }}
                      />
                      Copy Document
                    </Typography>
                  </Typography>
                )}
              {userDetail.userRoleName === UserRoleType.Attorney && (
                <Typography component={'div'} className="add-inner mt-2 mb-2">
                  <Typography component={'div'} className="add-inner-box" />
                  <Typography
                    className=" d-flex-a bu-color pl-2 pt-2 pb-1"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => checkToken()}>
                    <img
                      src={Bupload}
                      alt="im"
                      className="mr-1"
                      style={{ width: '20px !important' }}
                    />
                    Review Document
                    {countDetails.pending !== 0 && (
                      <Badge
                        badgeContent={Number(countDetails.pending)}
                        color="primary"
                        sx={{
                          maxWidth: 'max-content',
                          position: 'absolute',
                          right: '40px',
                          top: 15,
                        }}
                      />
                    )}
                  </Typography>
                </Typography>
              )}
              {userDetail.userRoleName === UserRoleType.Attorney &&
                ['1', '2', '3'].includes(tabValue) && (
                  <DocumentBulkDownload
                    documentList={
                      tabValue === '1'
                        ? beneficiaryDocuments &&
                          Array.isArray(beneficiaryDocuments) &&
                          beneficiaryDocuments.length
                          ? beneficiaryDocuments.filter(
                              (e) =>
                                e.multipleDocumentsList &&
                                e.multipleDocumentsList.length,
                            )
                          : []
                        : tabValue === '2'
                        ? petitioneryDocuments &&
                          Array.isArray(petitioneryDocuments) &&
                          petitioneryDocuments.length
                          ? petitioneryDocuments.filter(
                              (e) =>
                                e.multipleDocumentsList &&
                                e.multipleDocumentsList.length,
                            )
                          : []
                        : dependentDocuments &&
                          Array.isArray(dependentDocuments) &&
                          dependentDocuments.length
                        ? dependentDocuments
                            .filter(
                              (e) =>
                                e.multipledocumentslist &&
                                e.multipledocumentslist.length,
                            )
                            .map((el) => ({
                              ...el,
                              multipleDocumentsList: el.multipledocumentslist,
                            }))
                        : []
                    }
                    downloadFor={tabValue === '3' ? 'petition' : 'lead'}
                    userName={
                      tabValue === '3'
                        ? location?.state?.dependentName
                        : tabValue === '1'
                        ? location.state?.data?.name
                        : location.state?.data?.name
                    }
                    isDependent={tabValue === '3'}
                  />
                )}
            </Typography>
          </Typography>
        </Grid>
      )}
      {copyDocumentSection.open && (
        <CustomModalView
          open={copyDocumentSection.open}
          fullWidth={true}
          maxWidth={'xl'}
          scroll={'paper'}
          autoCloseDisable={true}
          isFilters={true}
          title="Petition List"
          filterOnChange={(searchvalue) => {
            setCopyDocParams((pre) => ({
              ...pre,
              documentType: searchvalue,
            }));
          }}
          close={() => {
            setCopyDocumentSection((pre) => ({
              ...pre,
              open: false,
            }));
            setCopyDocParams((pre) => ({
              ...pre,
              documentType: '',
            }));
          }}>
          {loader && <div className="liberty-loader" />}
          <DialogContent>
            <table className="lead-table table-hover">
              <thead>
                <tr>
                  <th scope="col" style={{ width: '50%' }}>
                    {'Document Type'}
                  </th>
                  <th scope="col">
                    Action
                    <Checkbox
                      checked={checkAll()}
                      onChange={(e) => selectAllDocument(e)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {copyDocumentSection && copyDocumentSection.data.length ? (
                  copyDocumentSection.data.map((data, index) => (
                    <tr key={index} style={{ cursor: 'pointer' }}>
                      <td>
                        <a
                          style={{
                            color: '#1093df',
                            fontFamily: ' Arial-Bold',
                            fontSize: '16px',
                          }}>
                          {data?.document_type}
                        </a>
                        {data.multipleDocumentsList.map((e, i) => {
                          return (
                            <Typography
                              key={i}
                              className="d-flex-a"
                              component={'div'}>
                              <Checkbox
                                checked={e?.checked ? true : false}
                                onChange={() =>
                                  removeOrAddInMultiDoc(
                                    copyDocumentSection.data,
                                    index,
                                    i,
                                    !e?.checked as any,
                                  )
                                }
                              />
                              <Typography>{e.original_name} </Typography>
                            </Typography>
                          );
                        })}
                      </td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {' '}
                        <Checkbox
                          size={'medium'}
                          checked={
                            data?.multipleDocumentsList &&
                            data?.multipleDocumentsList.every(
                              (multi) => multi.checked,
                            )
                          }
                          onChange={(e) =>
                            removeOrAddDoc(data, e.target.checked)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className={'text-center'}>
                    <th scope="row" colSpan={2}>
                      No Record(s) Found.
                    </th>
                  </tr>
                )}
              </tbody>
            </table>
          </DialogContent>
          {copyDocumentSection.data.filter((e) =>
            e.multipleDocumentsList.some((el) => el.checked),
          ).length ? (
            <DialogActions>
              <Typography
                component={'div'}
                className="d-flex-jae"
                sx={{ padding: '0 1.5rem' }}>
                <Button
                  className="n-add-btn"
                  sx={{
                    borderRadius: '5px !important',
                    float: 'right',
                    fontFamily: 'Arial-Regular !important',
                    fontSize: '0.9em !important',
                    height: '35px !important',
                    margin: '15px',
                    width: '149px !important',
                  }}
                  onClick={() => uploadSelectedDoc()}>
                  submit
                </Button>
              </Typography>
            </DialogActions>
          ) : (
            ''
          )}
        </CustomModalView>
      )}
    </Grid>
  );
};

export default UploadDocument;
