import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadIcon from '@mui/icons-material/Upload';
import {
  Button,
  DialogActions,
  DialogContent,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import Ledit from 'assets/images/ledit.png';
// import Upload from 'assets/images/uploadnow.svg';
import CustomModalView from 'components/CustomModalView/customModalView';
import React from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { addNewData } from 'services/CommonServices';
import { COMMON_ENDPOINT } from 'utils/Constant';
import {
  alertOptions,
  docAndPdfMimeTypes,
  docAndPdfType,
  fileTypeCheckSkillParsing,
  parsingDocMimeTypes,
  sizeView,
} from 'utils/helper';

import UploadIcon1 from '../../assets/images/clupload.svg';

type Props = {
  petitionId?: string;
  roleId?: number;
  leadId?: string;
  personId?: string;
  userName?: string;
  visaId?: string;
  callAssembleList?: any;
  uploadType?: string;
  openDialog?: () => void;
  openUploadDialog?: () => void;
  getUploadDocumentData?: any;
};
export const FileBulkUploadDialog: React.FC<Props> = (props: Props) => {
  const {
    petitionId = '',
    roleId = '',
    leadId = '',
    personId = '',
    userName = '',
    visaId = '',
    callAssembleList,
    uploadType,
    openDialog,
    openUploadDialog,
    getUploadDocumentData,
  } = props;
  const hiddenMultiFileInput = React.useRef(null);
  const [fileList, setFileList] = React.useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMultiFileChange = (files: FileList, type?: any) => {
    if (files && files.length) {
      const filesArr = [];

      // setDialogOpen(true);
      for (let index = 0; index < files.length; index++) {
        const element = type === 'drag' ? files[index] : files.item(index);
        if (fileList.findIndex((f) => f.name === element.name) === -1) {
          // Check if the file already exists
          filesArr.push(element);
        } else {
          toast.error(
            `${String(element.name)}  is already exists`,
            alertOptions,
          );
        }
      }
      setFileList((pre) => [...pre, ...filesArr]);
    }
    hiddenMultiFileInput &&
      hiddenMultiFileInput.current &&
      (hiddenMultiFileInput.current.value = '');
  };
  const totalSize = () => {
    let file = 0;
    fileList.forEach((element: File) => {
      // 92160 bytes is 90MB
      const fsize = element.size;
      file += fsize;
    });
    return sizeView(file);
  };
  const onmultiFileSubmit = () => {
    if (!fileList || fileList.length === 0) {
      toast.error('Files is required', alertOptions);
      return;
    }
    // file formate check
    const unwantedType = fileList.some((val) =>
      uploadType === 'assembleDocumentUpload'
        ? !docAndPdfType.includes(val.type)
        : !fileTypeCheckSkillParsing.includes(val.type),
    );
    if (unwantedType) {
      toast.error(
        uploadType === 'assembleDocumentUpload'
          ? 'You can upload documents of types PDF DOCX and DOC only'
          : 'You can upload documents of types JPEG, JPG, PNG , PDF, DOCX and DOC only',
        alertOptions,
      );
      const highlight = document.getElementsByClassName('fileError');
      if (highlight && highlight[0]) {
        highlight[0].scrollIntoView();
      }
      return;
    }
    // End
    let file = 0;
    fileList.forEach((element: File) => {
      // 92160 bytes is 90MB
      const fsize = element.size;
      file += Math.round(fsize / 1024);
    });
    if (file > 92160) {
      toast.error(
        'You cannot upload more than 90MB at a time. Please remove some files in order to reduce the size to below 90MB and then click the upload button.',
        alertOptions,
      );
      return;
    }
    fileUploadApiCall();
  };

  const fileUploadApiCall = async () => {
    try {
      if (uploadType === 'assembleDocumentUpload') {
        const formData = new FormData();
        formData.append('petitionId', String(petitionId));

        for (let index = 0; index < fileList.length; index++) {
          const element = fileList[index];
          formData.append('file', element);
        }
        setLoader(true);
        await addNewData(formData, COMMON_ENDPOINT.AssemblePetition);
        await callAssembleList();
      } else {
        const formData = new FormData();
        formData.append('documentFor', petitionId ? 'petition' : 'lead');
        formData.append('uploadedFor', String(roleId));
        formData.append(
          'leadId',
          petitionId ? String(petitionId) : String(leadId),
        );
        formData.append('uploadForId', personId);
        formData.append('userName', userName);
        formData.append('visaTypeId', visaId);

        for (let index = 0; index < fileList.length; index++) {
          const element = fileList[index];
          formData.append('file', element);
        }
        setLoader(true);
        await addNewData(formData, COMMON_ENDPOINT.BulkDocumentUpload);
      }

      setLoader(false);
      setFileList([]);
      setDialogOpen(false);
      toast.success(
        uploadType === 'assembleDocumentUpload'
          ? 'Files uploaded successfully.'
          : 'Your documents and data are being indexed and parsed. This may take some time. You will receive an email when it is complete or you can check back and a red circle will appear above the Review button on your screen. Click on Review to make necessary decisions.',
        { ...alertOptions, autoClose: true },
      );
    } catch (error) {
      setLoader(false);
      toast.error(error ? String(error) : '', alertOptions);
    }
  };
  const onDrop = (acceptedFiles) => {
    onMultiFileChange(acceptedFiles, 'drag');
  };
  return (
    <Typography component={'div'} className="add-inner">
      <Typography component={'div'} className="add-inner-box" />
      {uploadType === 'assembleDocumentUpload' ? (
        <>
          <Typography
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            component={'div'}
            className=" d-flex-a black-white-text pr-2"
            sx={{ cursor: 'pointer' }}
            onClick={handleClick}>
            <span style={{ color: '#002c69' }} className="d-flex-jae f-16">
              <UploadIcon style={{ fill: '#002c69' }} />
              Upload
            </span>
          </Typography>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}>
            <MenuItem
              sx={{ width: '250px !important' }}
              onClick={() => {
                handleClose();
                openUploadDialog && openUploadDialog();
                getUploadDocumentData();
              }}>
              Select Uploaded Documents
            </MenuItem>
            <MenuItem
              sx={{ width: '250px !important' }}
              onClick={() => {
                handleClose();
                openDialog && openDialog();
              }}>
              Select Build Documents
            </MenuItem>
            <MenuItem
              sx={{ width: '250px !important' }}
              onClick={() => {
                handleClose();
                setDialogOpen(true);
                // hiddenMultiFileInput.current.click();
              }}>
              Upload File
            </MenuItem>
          </Menu>
        </>
      ) : (
        <Typography
          component={'div'}
          className=" d-flex-a black-white-text pl-2 pt-2 pb-1"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            toast.error('Bulk Upload is not available');
            // setDialogOpen(true);
          }}>
          <img
            src={Ledit}
            alt="im"
            className="mr-1"
            style={{ width: '20px !important' }}
          />
          {'Bulk Upload'}
        </Typography>
      )}

      {/* {fileList && fileList.length > 0 && ( */}
      <CustomModalView
        open={dialogOpen}
        fullWidth={true}
        maxWidth={'xl'}
        minHeight={'75vh'}
        close={() => {
          setFileList([]), setDialogOpen(false);
        }}
        scroll={'paper'}
        title="Upload Document"
        subTitle={
          uploadType === 'assembleDocumentUpload'
            ? '(You can upload documents of types PDF, DOCX and DOC. You can upload upto a maximum of 90MB per upload)'
            : '(You can upload documents of types JPEG, JPG, PNG , PDF, DOCX and DOC. You can upload upto a maximum of 90MB per upload)'
        }
        autoCloseDisable={true}>
        {loader && <div className="liberty-loader" />}
        <Typography sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography
            sx={{ marginLeft: '25px', marginTop: '100px', width: '35%' }}>
            <Typography
              component={'div'}
              className="upload-drag-drop-assemble d-flex-ja pointer">
              <input
                type="file"
                multiple={true}
                hidden
                ref={hiddenMultiFileInput}
                onChange={(e) => onMultiFileChange(e.target.files)}
                accept={
                  uploadType === 'assembleDocumentUpload'
                    ? docAndPdfMimeTypes
                    : parsingDocMimeTypes
                }
              />
              <Dropzone onDrop={onDrop}>
                {({ getRootProps }) => {
                  return (
                    <>
                      <img
                        src={UploadIcon1}
                        alt="icon"
                        style={{ marginBottom: '25px' }}
                      />
                      <Typography
                        {...getRootProps({
                          className: 'dropzone',
                          onClick: (event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            hiddenMultiFileInput.current?.click();
                          },
                        })}
                      />
                    </>
                  );
                }}
              </Dropzone>

              <Typography className="f-16">
                Drag and drop file here, or click to select file
              </Typography>
            </Typography>
          </Typography>
          <Typography sx={{ marginLeft: '15px', width: '60%' }}>
            <DialogContent
              dividers={true}
              sx={{
                boxShadow: '0 3px 10px #e9f1ff',
                height: '450px !important',
                padding: '0px !important',
              }}>
              <table className="lead-table">
                <thead>
                  <tr>
                    <th scope="col">No</th>
                    <th scope="col">File Name</th>
                    <th scope="col">File Size</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody
                  style={{
                    paddingLeft: '13px',
                  }}>
                  {fileList && fileList.length > 0 ? (
                    <React.Fragment>
                      {fileList.map((multi, index) => {
                        return (
                          <tr
                            key={index}
                            className={
                              uploadType === 'assembleDocumentUpload'
                                ? !docAndPdfType.includes(multi.type)
                                  ? 'fileError'
                                  : ''
                                : !fileTypeCheckSkillParsing.includes(
                                    multi.type,
                                  )
                                ? 'fileError'
                                : ''
                            }>
                            <td style={{ paddingLeft: '14px' }}>
                              <a>{index + 1}</a>
                            </td>
                            <td style={{ color: '#1093df' }}>{multi.name}</td>
                            <td style={{ paddingLeft: '14px' }}>
                              {sizeView(multi.size)}
                            </td>
                            <td style={{ paddingLeft: '14px' }}>
                              <Tooltip title={'Delete'} placement="top">
                                <DeleteForeverIcon
                                  sx={{
                                    cursor: 'pointer',
                                    fill: 'gray',
                                    height: '25px',
                                    width: '25px',
                                  }}
                                  onClick={() =>
                                    setFileList((pre) => [
                                      ...(pre && pre.length
                                        ? pre.filter((e, i) => i !== index)
                                        : pre),
                                    ])
                                  }
                                />
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                      {fileList && fileList.length > 0 && (
                        <tr>
                          <td
                            colSpan={2}
                            style={{
                              fontWeight: 700,
                              textAlign: 'end',
                            }}>
                            Total =
                          </td>
                          <td style={{ fontWeight: 700 }} colSpan={3}>
                            {totalSize()}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ) : (
                    <tr className={'text-center'} style={{ height: '407px' }}>
                      <th scope="row" colSpan={5}>
                        No Record(s) Found.
                      </th>
                    </tr>
                  )}
                </tbody>
              </table>
            </DialogContent>
          </Typography>
        </Typography>
        <DialogActions>
          <Typography
            component={'div'}
            className="d-flex-jae"
            sx={{ padding: '0 1.5rem' }}>
            {/* <Button
              className="n-cancel-btn mr-1"
              sx={{
                backgroundColor: '#bcc7dc !important',
                borderRadius: '5px !important',
                float: 'right',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '149px !important',
              }}
              onClick={() => {
                hiddenMultiFileInput.current.click();
              }}> */}
            {/* {fileList && fileList.length > 0 ? 'Add More' : 'Browse'} */}
            {/* </Button> */}
            {/* {fileList && fileList.length > 0 && ( */}
            <Button
              className="n-add-btn"
              sx={{
                borderRadius: '5px !important',
                fontFamily: 'Arial-Regular !important',
                fontSize: '0.9em !important',
                height: '35px !important',
                width: '149px !important',
              }}
              disabled={fileList && fileList.length > 0 ? false : true}
              onClick={() => onmultiFileSubmit()}>
              {'Upload'}
            </Button>
            {/* )} */}
          </Typography>
        </DialogActions>
      </CustomModalView>
      {/* )} */}
    </Typography>
  );
};
